

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAgZvXaWR1u5U9iMf-4z9sbN-zpL9pOTwM",
  authDomain: "dlightng-d70e7.firebaseapp.com",
  projectId: "dlightng-d70e7",
  storageBucket: "dlightng-d70e7.appspot.com",
  messagingSenderId: "253348098900",
  appId: "1:253348098900:web:3bdaffbbe8ba0f11578ec1",
  measurementId: "G-J3T0QZER6Z"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
