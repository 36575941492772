<template>
  <v-container fluid>
    <v-row>
      <router-view></router-view>
      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["isAuthenticated"]),
  },
  created: function () {
    if (!this.isAuthenticated) {
      this.$router.replace({ name: "Login" });
    }
  },
  data() {
    return {
      links: [],
    };
  },
};
</script>

<style lang="css" scoped>
.name {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
</style>