import firebase from 'firebase/app';
import 'firebase/auth'; import axios from "axios";
import config from '../../utils/config';
const api = {
    namespaced: true,
    actions: {
        httpGet: async (context, _data) => {
            const options = {
                url: `${config.siteUrl + _data.url}`,
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                params: _data.data
            };
            try {
                if (_data.withAuth) {
                    let token = await firebase.auth().currentUser.getIdToken();
                    options.headers['Authorization'] = `bearer ${token}`;
                }
                let resp = axios(options);
                return resp;
            } catch (error) {
                if (
                    error.message.indexOf("404") != -1 ||
                    error.message == "Network Error"
                ) {
                    throw new Error('Could not connect to server please try again.');
                }
                else {
                    throw error;
                }
            }
        },
        httpPost: async (context, _data) => {
            const options = {
                url: `${config.siteUrl + _data.url}`,
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: _data.data
            };
            try {
                if (_data.withAuth) {
                    let token = await firebase.auth().currentUser.getIdToken();
                    options.headers['Authorization'] = `bearer ${token}`;
                }
                let resp = axios(options);
                return resp;
            } catch (error) {
                if (
                    error.message.indexOf("404") != -1 ||
                    error.message == "Network Error"
                ) {
                    throw new Error('network_error');
                }
                else {
                    throw error;
                }
            }
        },
        httpPatch: async (context, _data) => {
            const options = {
                url: `${config.siteUrl + _data.url}`,
                method: 'patch',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: _data.data
            };
            try {
                if (_data.withAuth) {
                    let token = await firebase.auth().currentUser.getIdToken();
                    options.headers['Authorization'] = `bearer ${token}`;
                }
                let resp = axios(options);
                return resp;
            } catch (error) {
                if (
                    error.message.indexOf("404") != -1 ||
                    error.message == "Network Error"
                ) {
                    throw new Error('network_error');
                }
                else {
                    throw error;
                }
            }
        },
        httpDelete: async (context, _data) => {
            const options = {
                url: `${config.siteUrl + _data.url}`,
                method: 'delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                // params: _data.data
            };
            try {
                if (_data.withAuth) {
                    let token = await firebase.auth().currentUser.getIdToken();
                    options.headers['Authorization'] = `bearer ${token}`;
                }
                let resp = axios(options);
                return resp;
            } catch (error) {
                if (
                    error.message.indexOf("404") != -1 ||
                    error.message == "Network Error"
                ) {
                    throw new Error('Could not connect to server please try again.');
                }
                else {
                    throw error;
                }
            }
        },
        httpPostMP: async (context, _data) => {
            let options = {
                url: `${config.siteUrl + _data.url}`,
                method: 'post',
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data'
                },
                data: _data.data
            };
            try {
                if (_data.withAuth) {
                    let token = await firebase.auth().currentUser.getIdToken();
                    options.headers['Authorization'] = `bearer ${token}`;
                }
                let resp = axios(options);
                return resp;
            } catch (error) {
                if (
                    error.message.indexOf("404") != -1 ||
                    error.message == "Network Error"
                ) {
                    throw new Error('network_error');
                }
                else {
                    throw error;
                }
            }
        }
    }
}
export default api;