
import store from '../store';
const toast = {
    errorMsgs: {
        generic: 'Something went wrong, please try later',
        noBalance: 'Insufficient balance',
        invalidPhone: 'Phone number is invalid',
        invalidPhoneCode: 'Code provided is invalid',
    },
    showInfo: (msg) => {
        store.dispatch('showMessage', {
            text: msg,
            false: true,
        });
    },
    showError: (error) => {
        let errMsg = toast.errorMsgs.generic;
        if (
            error.response &&
            error.response.data &&
            error.response.data.message
        ) {
            errMsg = error.response.data.message;
        } else if (error.message) {
            errMsg = error.message;
        }
        store.dispatch('showMessage', {
            text: errMsg,
            error: true,
        });
    }
}
export default toast;

//"http://localhost:5201/anioma-gas/us-central1",//