import Vue from 'vue'
import Vuex from 'vuex'
import api from './modules/api';
import firebase from 'firebase/app';
import 'firebase/auth';
import { min, max, format, formatRelative, formatDistance, startOfMonth, endOfDay, startOfWeek, startOfDay, startOfYear, startOfQuarter } from 'date-fns'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    user: {},
    isAuthenticated: false,
    snackbar: false,
    snackBarText: "",
    snackBarColor: "purple",
    account: {},
    games: {},
    currency: {
      symbol: '₦',
      name: "naira"
    },
    country: {},
  },
  getters: {
    getDayStartAndEnd: () => (date) => {
      let dates = [startOfDay(date), endOfDay(date)];
      return { start: max(dates), end: min(dates) };
    },
    getSortedDates: () => (dates) => {
      let _dates = [dates[0], dates[1]];
      return { start: max(_dates), end: min(_dates) };
    },
    getEndDate: () => (duration) => {
      switch (duration) {
        case 'today':
          return startOfDay(new Date());
        case 'week':
          return startOfWeek(new Date());
        case 'month':
          return startOfMonth(new Date());
        case 'quarter':
          return startOfQuarter(new Date());
        case 'year':
          return startOfYear(new Date());
        default:
          return startOfMonth(new Date());
      }
    },
    formatDateRelative: () => (date) => {
      return formatRelative(date, new Date());
    },
    formatDateDistance: () => (date) => {
      return formatDistance(date, new Date());
    },
    getUserId: (state) => {
      return state.user.uid;
    },
    isAdmin: (state) => {
      return state.user.uid === "l167igmISRdpKxpQJkWHA3uCV9T2";
    },
    formatDateMed: () => (date) => {
      return date ? format(new Date(date), "MMM dd, yyyy") : ' - ';
    },
    formatTime: () => (date) => {
      return date ? format(new Date(date), "h:mm:ss b") : ' - ';
    },
    formatDateLong: () => (date) => {
      return date ? format(new Date(date), "EEEE, MMM dd, yyyy") : ' - ';
    },
    formatDayName: () => (date) => {
      return date ? format(new Date(date), "EEEE") : ' - ';
    },
    formatCurrencyDecimal: (state) => (n, c, symbol) => {
      c = c ? c : 2;

      let d = '.';
      let t = ',';
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = i.length
      j = j > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(
        n - i).toFixed(c).slice(2) : '');
      return symbol ? state.currency.symbol + val : val;
    },
    formatCurrency: (state) => (n, c, symbol) => {
      c = c ? c : 2;
      let t = ',';
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = i.length
      j = j > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t);
      return symbol ? state.currency.symbol + val : val;
    },
  },
  mutations: {

    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setSnackBarText(state, payload) {
      state.snackBarText = payload;
    },
    setSnackBarColor(state, payload) {
      state.snackBarColor = payload;
    },
    setBusy(state, val) {
      state.loading = val;
    },
    setUser(state, user) {
      state.user = user;
      if (user && user.uid) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
  },
  actions: {
    logout: async ({ commit }) => {
      await firebase.auth().signOut();
      commit('setUser', {});
      window.location.href = "/login";
      // dispatch('stopSocket');
    },
    login: ({ commit }, user) => {
      commit('setUser', user);
      // dispatch('setupSocket', user);
    },
    sendChangePasswordEmail: async (context, email) => {
      await firebase.auth().sendPasswordResetEmail(email);
    },
    verifyPassword: async (context, oldPassword) => {
      await firebase.auth().currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, oldPassword));
    },
    sendPasswordChange: async ({ dispatch }, data) => {
      let params = await dispatch('httpPost', { withAuth: true, url: '/api/change_password', data: data }, { root: true });
      return JSON.parse(JSON.stringify(params.data));
    },
    getToken: async () => {
      return await firebase.auth().currentUser.getIdToken();
    },
    showMessage: ({ commit }, options) => {
      commit('setSnackBarText', options.text);
      commit('setSnackBarColor', options.error ? 'deep-orange' : 'info');
      commit('setSnackbar', true);
    },
  },
  modules: {
    api: api,
  }
})
