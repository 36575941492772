import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import store from '../store';
import toast from '../utils/toast';

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    props: true,
    children: [
      {
        path: "/",
        name: 'Plans',
        component: () => {
          return import(/* webpackChunkName: "plans" */ '../components/plans/plansView.vue');
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin == false) {
            toast.showError(new Error('You do not have the required permission, contact admin.'))
            return next(false)
          }
          next();
        }
      },
      {
        path: "accounts",
        name: 'Accounts',
        component: () => {
          return import(/* webpackChunkName: "accounts" */ '../components/accounts/accountsView.vue');
        },
      },
      {
        path: "payments",
        name: 'Payments',
        component: () => {
          return import(/* webpackChunkName: "payments" */ '../components/payments/payView.vue');
        },
      },
      // {
      //   path: "plans",
      //   name: 'Plans',
      //   component: () => {
      //     return import(/* webpackChunkName: "plans" */ '../components/plans/plansView.vue');
      //   },
      // },


    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => {
      return import(/* webpackChunkName: "login" */ '../views/login.vue');
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
