<template>
  <v-app>
    <v-navigation-drawer
      dark
      v-model="drawer"
      app
      v-if="!busy && isAuthenticated"
    >
      <v-layout column fill-height>
        <div
          class="font-weight-light ml-5 my-3 title orange--text text--lighten-1"
        >
          DLight Admin
        </div>
        <v-divider></v-divider>
        <v-list dense class="primary_ darken-1">
          <v-list-item
            :disabled="link.disabled"
            v-for="link in links"
            :key="link.name"
            :to="link.url"
            exact
          >
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <div class="name">{{ link.name }}</div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar app v-if="!busy && isAuthenticated">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer></v-spacer>
      <div class="d-flex">
        <div class="text-center" v-if="user && user.email">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn text dark v-on="on">
                <v-icon size="34" color="teal darken-1"
                  >mdi-account-circle</v-icon
                >
                <v-icon color="teal darken-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img v-if="user.photoURL" :src="user.photoURL" alt="John" />
                    <v-icon size="46" color="grey" v-else
                      >mdi-account-circle</v-icon
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-name">{{
                      user.displayName
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      user.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                    <v-btn :class="fav ? 'red--text' : ''" icon @click="fav = !fav">
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>-->
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-list dense>
                <!-- 
                <v-list-item @click="logout">
                  <v-list-item-avatar class="grey lighten-3">
                    <v-icon>mdi-history</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>My Transactions</v-list-item-title>
                </v-list-item>-->

                <!-- <v-list-item @click="logout">
                  <v-list-item-avatar class="grey lighten-3">
                    <v-icon>mdi-card-account-details-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Manage Profile</v-list-item-title>
                </v-list-item>-->

                <v-list-item @click="logout">
                  <v-list-item-avatar class="grey lighten-3">
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>

                <!--  <v-list-item>
                  <v-list-item-action>
                    <v-switch v-model="hints" color="purple"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Enable hints</v-list-item-title>
                </v-list-item>-->
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <v-container
        class="primary justify-center align-center"
        fluid
        fill-height
        v-if="busy"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <router-view
        style="min-height: calc(100vh - 150px)"
        v-if="!busy"
      ></router-view>
      <Footer v-if="!busy" />
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackState"
          :color="snackBarColor"
          class="center"
          :timeout="timeout"
        >
          <div style="width: 100%; text-align: center">{{ snackBarText }}</div>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "App",
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...mapMutations(["setSnackbar", "login", "logout"]),
    ...mapActions(["login", "logout", "showMessage"]),
    routeChange: function () {},
    goToRoute: function (path) {
      this.menu = false;
      this.$router.push({ path: path });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  watch: {
    // call again the method if the route changes
    $route: "routeChange",
  },

  computed: {
    ...mapState([
      "snackbar",
      "snackBarText",
      "snackBarColor",
      "isAuthenticated",
      "user",
      "account",
    ]),
    ...mapGetters(["getWalletBalance"]),
    // ...mapGetters([]),
    snackState: {
      get() {
        return this.snackbar;
      },
      set(val) {
        this.setSnackbar(val);
      },
    },
  },
  created: function () {
    // firebase.auth().useEmulator("http://localhost:9099/");
    this.$vuetify.theme.themes.light.primary = "#0074b6";
    this.$vuetify.theme.dark = true;
    //hide footer for Login
    // if (this.$router.history.current.name === "LoginNeeded") {
    //   this.showFooter = false;
    // } else {
    //   this.showFooter = true;
    // }
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log(
        "Firebase User===>>",
        user || "not logged in",
        this.$router.history.current.name
      );
      try {
        this.menu = false;
        if (user) {
          this.login(user);
          const continueUrl = this.$router.history.current.query.continue;
          if (continueUrl) {
            this.$router.replace({ path: continueUrl });
          } else if (
            this.$router.history.current.name === "Login" ||
            this.$router.history.current.name === "Register" ||
            this.$router.history.current.path === "/"
          ) {
            this.$router.replace({ name: "Plans" });
          }
          setTimeout(() => {
            this.busy = false;
          }, 200);
        } else {
          this.busy = false;
          if (this.$router.history.current.name !== "Login") {
            this.$router.replace({ name: "Login" });
          }
        }
      } catch (error) {
        console.error(error);
        this.showMessage({ error: true, text: "Something went wrong!" });
        this.busy = false;
        //TODO show error page with reload
      }
    });
  },
  data() {
    return {
      isMobile: false,
      showFooter: true,
      menu: false,
      drawer: false,
      timeout: 3000,
      busy: true,
      links: [
        // {
        //   name: "Dashboard",
        //   url: "/admin/",
        //   icon: "mdi-finance",
        // },

        {
          name: "Plans",
          url: "/admin/",
          icon: "mdi-fire",
          disabled: false,
        },
        {
          name: "Accounts",
          url: "/admin/accounts",
          icon: "mdi-account-supervisor",
          disabled: false,
        },
        {
          name: "Payments",
          url: "/admin/payments",
          icon: "mdi-history",
          disabled: false,
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.d-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #555555;
}
.bal {
  margin-left: 30px;
  display: block;
}
</style>
<style lang="css">
.v-slide-group__content {
  /* background-color: #fafafa; */
}
</style>